import React, { useState } from 'react'
import FormBoxLayout from 'src/components/FormBoxLayout'
import Form from 'src/components/Form'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import ActionButtonFormGroup from 'src/components/ActionButtonFormGroup'
import Link from 'src/components/Link'

function SignUp () {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')

  const handleSignUp = () => {
    alert('coming soon')
  }

  const title = 'Sign up'

  return (
    <FormBoxLayout title={title}>
      <Form label={title} onSubmit={handleSignUp}>
        <TextFieldFormGroup
          inputProps={{
            autoFocus: true,
            label: 'Email',
            value: email,
            placeholder: 'satoshi@example.com',
            onChange: setEmail,
            type: 'text'
          }}
        />
        <TextFieldFormGroup
          inputProps={{
            label: 'Password',
            value: password,
            placeholder: 'Password',
            onChange: setPassword,
            type: 'password'
          }}
        />
        <TextFieldFormGroup
          inputProps={{
            label: 'Confirm Password',
            value: passwordConfirm,
            placeholder: 'Confirm Password',
            onChange: setPasswordConfirm,
            type: 'password'
          }}
        />
        <ActionButtonFormGroup buttonText={'Sign up'} />
      </Form>
      <div>
        <Link href="/login">Already have an account</Link>
      </div>
    </FormBoxLayout>
  )
}

export default SignUp
